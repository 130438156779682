import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { GET_WITHDRAW } from "@/graphql/withdraw";
import { getBankName } from "@/config";
import { imageUrlApi } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BANK_URL } from "@/config";
export default {
  name: "WithdrawDetail",
  mixins: [validationMixin],
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E01\u0E23\u0E30\u0E40\u0E1B\u0E4B\u0E32\u0E40\u0E07\u0E34\u0E19",
      route: "/wallet"
    }, {
      title: "\u0E16\u0E2D\u0E19\u0E40\u0E07\u0E34\u0E19"
    }]);
  },
  data: function data() {
    var id = this.$route.params.id;
    return {
      BANK_URL: BANK_URL,
      valid: true,
      id: Number(id),
      previewImage: null,
      file: null,
      fileValid: true,
      rules: {
        required: function required(value) {
          return !!value || "กรุณากรอกข้อมูล";
        }
      }
    };
  },
  methods: {
    getBankName: getBankName,
    imageUrlApi: imageUrlApi,
    format_date: function format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        case 2:
          return "warning";

        case 3:
          return "success";

        default:
          return "primary";
      }
    }
  },
  apollo: {
    withdrawDetail: {
      query: GET_WITHDRAW,
      variables: function variables() {
        return {
          id: this.id
        };
      }
    }
  }
};